<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-first_name" class="form-label">
                        Имя
                        <sup v-if="v$.form.first_name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.first_name.$errors.length" for="form-first_name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.first_name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-first_name"
                        v-model="form.first_name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.first_name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-last_name" class="form-label">
                        Фамилия
                        <sup v-if="v$.form.last_name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.last_name.$errors.length" for="form-last_name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.last_name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-last_name"
                        v-model="form.last_name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.last_name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-phone" class="form-label">
                        Логин (телефон)
                        <sup v-if="v$.form.phone.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.phone.$errors.length" for="form-phone" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.phone.$errors[0].$message) }}
                    </label>

                    <PhoneComponent
                        id="form-phone"
                        :value="form.phone"
                        name="phone"
                        :error="v$.form.phone.$errors.length"
                        @change="handleChangePhone"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-password" class="form-label">Пароль</label>
                    <input
                        id="form-password"
                        v-model="form.password"
                        type="password"
                        class="form-control w-full"
                        :disabled="!changePassword"
                        placeholder=""
                    />
                    <div v-if="!changePassword" class="change__password">
                        <a href="#" class="btn btn-secondary" @click.prevent="changePassword = !changePassword">
                            Сменить пароль
                        </a>
                    </div>
                </div>

                <div class="col-span-6 relative">
                    <label for="form-bitrix_user_id" class="form-label">Id пользователя в Битрикс24</label>
                    <input
                        id="form-bitrix_user_id"
                        v-model="form.bitrix_user_id"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-rop" class="form-label">Руководитель отдела продаж</label>
                    <select id="form-rop" v-model="form.rop" name="rop" class="form-select sm:mr-2" autocomplete="off">
                        <option v-for="(opt, optIdx) in rops" :key="optIdx" :value="opt.id">{{ opt.name }}</option>
                    </select>
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'managers' }" class="btn btn-secondary w-24 mb-2 mr-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { managersMixin } from '@/mixins/form/managers-mixin';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import { helper as $h } from '@/utils/helper';
import PhoneComponent from '@/components/base/PhoneComponent.vue';
import { phoneClear } from '@/utils/phone';

export default {
    name: 'Edit',
    components: { PhoneComponent, Preloader },
    mixins: [managersMixin, errorResponse],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                first_name: { required },
                last_name: { required },
                phone: { required },
            },
        };
    },
    data() {
        return {
            changePassword: false,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Менеджеры / Редактировать');
        await this.fetchRops();
        await this.fetchManager();
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                await this.axios.post(
                    '/managers/' + this.$route.params.id,
                    { ...this.form, phone: phoneClear(this.form.phone) },
                    {
                        params: {
                            _method: 'patch',
                        },
                    }
                );
                this.loading = false;

                if (flag) {
                    this.$notify('Запись сохранена');
                } else {
                    await this.$router.push({ name: 'managers' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
        handleChangePhone(value) {
            if (typeof value === 'string') this.form.phone = value;
        },
    },
};
</script>

<style scoped lang="scss">
.change__password {
    position: absolute;
    top: 42%;
    right: 0;
}
</style>
